import { template as template_f7180067b8b6454c8a0f18781f6d5a49 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_f7180067b8b6454c8a0f18781f6d5a49(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
