import { template as template_14f3e045a82a452190aa6429da8b462c } from "@ember/template-compiler";
const FKLabel = template_14f3e045a82a452190aa6429da8b462c(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
